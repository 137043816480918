import {
  americanExerciseUrl,
  americanMotivateUrl,
  americanRebootUrl,
  content7Url,
  exerciseUrl,
  wegovyAdaptContentUrl,
  wegovyGrowContentUrl,
  wegovyProgressContentUrl,
  motivateUrl,
  ourpathContent6NDPPUrl,
  ourpathContent6Type2DiabetesUrl,
  ourpathContent6v2CoreLightAmericanUrl,
  ourpathContent6v2CoreLightAustralianUrl,
  ourpathContent6v2CoreLightUrl,
  rebootUrl,
  selfPacedConsumerUrl,
  vitalityExperimentXUrl,
  wms1SelfGuidedContentUrl,
  wms3CoachedContentUrl,
  mounjaroAdaptContentUrl,
  content7AmericanUrl,
  content7v2AmericanUrl,
} from './contentUrls';
import {
  CountryCodeType,
  CountryCodeWithoutUnknownType,
} from './internationalConstants';

export const oneDay = 24 * 60 * 60 * 1000;

export const communityPostMaxLength = 7000;

export const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/;

const phoneRegexIE =
  /^\s*(((\+353\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{3})|((\+353\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+353\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?\s*$/;
const phoneRegexGB =
  /^\s*(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?\s*$/;
const phoneRegexES = /^\s*(\+34|0034|34)\s?([6-9])\s?(\d(\s|-)?){8}\s*$/;
const phoneRegexFR = /^\s*(\+33|0033|33|0)\s?(\d\s?){9}$\s*/;
const phoneRegexBE = /^\s*(\+32|0032|32|0)\s?(\d\s?){8,9}\s*$/;
const phoneRegexNL = /^\s*(\+31|0031|31|0)\s?(\d\s?){9}\s*$/;
const phoneRegexAU =
  /^\s*(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}\s*$/;
const phoneRegexNZ =
  /^\s*(0|(\+64(\s|-)?)){1}\d{1}(\s|-)?\d{3}(\s|-)?\d{4}\s*$/;
const phoneRegexNZMobile =
  /^\s*(0|(\+64(\s|-)?)){1}\d{2}(\s|-)?\d{3}(\s|-)?\d{4}\s*$/;

export const phoneRegex = new RegExp(
  `(${phoneRegexIE.source})|(${phoneRegexGB.source})|(${phoneRegexES.source})|(${phoneRegexFR.source})|(${phoneRegexBE.source})|(${phoneRegexNL.source})|(${phoneRegexAU.source})|(${phoneRegexNZ.source})|(${phoneRegexNZMobile.source})`,
);

export const postcodeRegexGB =
  /^(GIR ?0AA|[A-PR-UWYZ] ?([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ? ?[0-9] ?[ABD-HJLNP-UW-Z]{2})/i;
export const postcodeRegexIE =
  /\b(?:(a(4[125s]|6[37]|7[5s]|[8b][1-6s]|9[12468b])|c1[5s]|d([0o][1-9sb]|1[0-8osb]|2[024o]|6w)|e(2[15s]|3[24]|4[15s]|[5s]3|91)|f(12|2[368b]|3[15s]|4[25s]|[5s][26]|9[1-4])|h(1[2468b]|23|[5s][34]|6[25s]|[79]1)|k(3[246]|4[5s]|[5s]6|67|7[8b])|n(3[79]|[49]1)|p(1[247]|2[45s]|3[126]|4[37]|[5s][16]|6[17]|7[25s]|[8b][15s])|r(14|21|3[25s]|4[25s]|[5s][16]|9[35s])|t(12|23|34|4[5s]|[5s]6)|v(1[45s]|23|3[15s]|42|9[2-5s])|w(12|23|34|91)|x(3[5s]|42|91)|y(14|2[15s]|3[45s]))\s?[abcdefhknoprtsvwxy\d]{4})\b/i;
export const postcodeRegexFR = /^(?:[0-8]\d|9[0-8])\d{3}$/;
export const postcodeRegexES = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/;
export const postcodeRegexBE = /^(?:(?:[1-9])(?:\d{3}))$/;
export const postcodeRegexNL = /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i;
export const postcodeRegexDE = /^\d{5}$/;

export const ACCEPTED_CONTENT_LANGUAGES = [
  'en', // English
  'ur', // Urdu
  'hi', // Hindi
  'ar', // Arabic
  'gu', // Gujarati
  'bn', // Bengali
  'ta', // Tamil
  'pl', // Polish
  'zh', // Chinese
  'pa', // Punjabi
  'cy', // Welsh
] as const;

export enum NHS_CONTRACTS {
  NDPP_HYPERTENSION_THRIVE_TRIBE = 'NDPP Hypertension: Thrive Tribe',
  NDPP_HYPERTENSION_REED = 'NDPP Hypertension: Reed',
  NDPP_2022_THRIVE_TRIBE = 'NDPP 2022: Thrive Tribe',
  NDPP_2022_REED = 'NDPP 2022: Reed',
  TIER_3_MID_SOUTH_ESSEX = 'Tier 3 weight management: Mid and South Essex',
}

export const type2Contracts = [
  'Type 2: NWL',
  'Type 2: Portsmouth',
  'Type 2: Liverpool',
  'Type 2: SWL',
  'Type 2: East Berkshire',
  'Type 2: Test Beds',
  'Type 2: Hastings',
  'Type 2: LAS and TfL',
  'Type 2: Doncaster',
  'Type 2: Scottish Highlands',
  'Type 2: Oxford',
  'Type 2: Dumfries and Galloway',
  'Type 2: Tayside',
];

export const TWELVE_WEEK_NDPP_CONTRACTS = [
  'NDPP Early Delivery',
  'NDPP Full Delivery',
];

export const NDPP_2022_CONTRACTS: string[] = [
  NHS_CONTRACTS.NDPP_2022_THRIVE_TRIBE,
  NHS_CONTRACTS.NDPP_2022_REED,
];

export const ENGLISH_NDPP_CONTRACTS = [
  ...TWELVE_WEEK_NDPP_CONTRACTS,
  ...NDPP_2022_CONTRACTS,
];

// 6-months coaching, content 6 NDPP, Tech no tracker
export const SCOTTISH_NDPP_CONTRACTS = [
  'NDPP Scotland: Tayside',
  'NDPP Scotland: Dumfries and Galloway',
  'NDPP Scotland: Grampian',
  'NDPP Scotland: Western Isles',
];

/** Includes National + Scottish NDPP contracts */
export const NDPP_DIABETES_CONTRACTS = [
  ...ENGLISH_NDPP_CONTRACTS,
  ...SCOTTISH_NDPP_CONTRACTS,
];

export const NDPP_HYPERTENSION_CONTRACTS: string[] = [
  NHS_CONTRACTS.NDPP_HYPERTENSION_REED,
  NHS_CONTRACTS.NDPP_HYPERTENSION_THRIVE_TRIBE,
];

export const weightManagementContractsNoTech = [
  'Weight management: Bath',
  'Weight management: Betsi Cadwaladr',
  'Weight management: Cheshire East',
];

export const weightManagementContractsTech = [
  'Diabetes prevention: Dumfries and Galloway',
  'Weight management: Lincolnshire',
  'Weight management: Dumfries and Galloway',
  'Weight management: Kingston',
  'Weight management: Darlington',
  'Weight management: Cwm Taf',
  'Weight management: Tayside',
  'Weight management: Lanarkshire',
  'Weight management: East of Scotland',
  'Weight management: Forth Valley',
  'Weight management gynae: East of Scotland',
  'Weight management: Orkney',
  // We are migrating ABL Nottingham to tech
  'Weight management: ABL Nottingham',
  'Weight management: ABL Gloucestershire',
  'Weight management: ABL Oldham',
  'Weight management: Western Isles',
  'Weight management: Grampian',
  'Weight management: Ayrshire & Arran',
];

// Locally commissioned contracts (e.g. by a health board or council), mixture of tech and no tech, all 12-weeks content 6
export const weightManagementContracts = [
  ...weightManagementContractsNoTech,
  ...weightManagementContractsTech,
];

// Defining a new constant for a small feasibility trial we are running with oxford
// Contract will have a new groupType and separate group allocation so needs a separate classification
export const adolescentWeightManagementContracts = [
  'Adolescent weight management: Oxford',
];

export const nationalWeightManagementServiceContracts = [
  // content 6 wms1, 40 person groups, digital only
  'WMS level 1: Self guided',
  // content 6 wms3, 40 person groups, digital only with coaching
  'WMS level 3: Coached',
];

// New variation in contract type - locally commissioned weight management (e.g. by a council), but more suitable to go into WMS level 3 groups
// content 6 wms3, digital only with coaching, so makes sense to be allocated into WMS level 3: Coached groups to align with similar programme features
export const digitalOnlyWeightManagementContracts = [
  'Weight management: Dorset',
];

// Tier 3 weight management contract to be digital only and go onto the modularised
// "concierge" programme content
export const tier3weightManagementContracts = [
  'Tier 3 weight management: Scottish Highlands',
  'Tier 3 weight management: Dumfries and Galloway',
  'Tier 3 weight management: Tayside',
];

// Tier 3 weight management contracts to go onto the Active Weight Loss phase
export const tier3weightManagementAWLContracts = [
  'Tier 3 weight management (active weight loss): Scottish Highlands',
  'Tier 3 weight management (active weight loss): Dumfries and Galloway',
  'Tier 3 weight management (active weight loss): Tayside',
];

// SWL became no-tech after we filled lisences from tech contract
// Betsi has also transitioned from tech to no-tech
export const noTechNHSLocalContracts = [
  'Type 2: Test Beds',
  'Type 2: SWL',
  'Weight management: Bath',
  'Weight management: Betsi Cadwaladr',
  'Adolescent weight management: Oxford',
  'Weight management: Cheshire East',
];

export const allNoTechOrDigitalNHSContracts = [
  ...noTechNHSLocalContracts,
  ...nationalWeightManagementServiceContracts,
  ...digitalOnlyWeightManagementContracts,
  ...tier3weightManagementContracts,
  ...tier3weightManagementAWLContracts,
];

export const selfGuidedNHSContracts = ['WMS level 1: Self guided'];

// digital is noTech but without the sending of the recipe book
export const countriesAvailableByPlan: Record<
  string,
  readonly CountryCodeType[]
> = {
  coreNoTech: ['GB', 'IE', 'FR', 'BE', 'DE', 'NL', 'ES'],
  coreTech: ['GB', 'IE'],
  sustain: [
    'GB',
    'IE',
    'FR',
    'DE',
    'DK',
    'NL',
    'ES',
    'AU',
    'SE',
    'FI',
    'NO',
    'IS',
    'NZ',
  ],
  ongoing: [
    'GB',
    'IE',
    'FR',
    'DE',
    'DK',
    'NL',
    'ES',
    'AU',
    'SE',
    'FI',
    'NO',
    'IS',
    'NZ',
    'US',
    'CA',
  ],
  digital: ['AU', 'SE', 'FI', 'NO', 'IS', 'DK', 'NZ', 'US', 'CA'],
} as const;

type CountryCodePricingMapType = Record<
  CountryCodeType,
  {
    properName: string;
    currencySymbol: string;
    currency: CurrencyType;
    noTechPricePerMonth: number;
    techPricePerMonth?: number;
    selfGuidedPricePerMonth?: number;
    coreLightPricePerMonth?: number;
    sustainPricePerMonth: number;
    ongoingPricePerMonth: number;
    selfGuidedUpfrontSavings?: number;
    coreLightUpfrontSavings?: number;
    techUpgradePrice?: number;
    higherPriced443NoTech?: number;
    higherPriced443Tech?: number;
    techMonthlyPlanId?: string;
    noTechMonthlyPlanId: string;
    selfGuidedMonthlyPlanId?: string;
    selfGuidedTechMonthlyPlanId?: string;
    coreLightMonthlyPlanId?: string;
    techUpFrontPlanId?: string;
    blackFridayTechUpFrontPlanId?: string;
    noTechUpFrontPlanId: string;
    blackFridayNoTechUpFrontPlanId: string;
    selfGuidedUpFrontPlanId?: string;
    coreLightUpFrontPlanId?: string;
    sustainPlanId: string;
    ongoingPlanId: string;
    ongoingLegacyPlanId: string;
    coreLight2TechMonthlyPlanId?: string;
    coreLight2TechUpFrontPlanId?: string;
    coreLight2NoTech6MonthsMonthlyPlanId?: string;
    coreLight2NoTech6MonthsUpFrontPlanId?: string;
    coreLight2NoTech12MonthsMonthlyPlanId?: string;
    coreLight2NoTech12MonthsUpFrontPlanId?: string;
    coreLight2NoTech6MonthsDiscountedMonthlyPlanId?: string;
    coreLight2NoTech12MonthsDiscountedMonthlyPlanId?: string;
    coreLight2NoTechMonthlyPlanId: string;
    coreLight2NoTechUpFrontPlanId: string;
  }
>;

export const countryCodePricingMap: CountryCodePricingMapType = {
  Unknown: {
    properName: 'Unknown',
    currencySymbol: '£',
    currency: 'gbp',
    noTechPricePerMonth: 49,
    techPricePerMonth: 55,
    selfGuidedPricePerMonth: 30,
    coreLightPricePerMonth: 40,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 40,
    selfGuidedUpfrontSavings: 10,
    coreLightUpfrontSavings: 10,
    techUpgradePrice: 15,
    higherPriced443NoTech: 50,
    higherPriced443Tech: 65,
    techMonthlyPlanId: 'coreTechMonthly55GBP',
    noTechMonthlyPlanId: 'coreNoTechMonthly40GBP',
    selfGuidedMonthlyPlanId: 'selfGuided40GBP',
    selfGuidedTechMonthlyPlanId: 'selfGuidedMonthlyTech40GBP',
    coreLightMonthlyPlanId: 'coreLight40GBP',
    techUpFrontPlanId: 'coreTechUpFront145GBP',
    blackFridayTechUpFrontPlanId: 'blackFridayCoreTechUpFrontGBP',
    noTechUpFrontPlanId: 'coreNoTechUpFront110GBP',
    blackFridayNoTechUpFrontPlanId: 'blackFridayNoTechUpFrontGBP',
    selfGuidedUpFrontPlanId: 'selfGuided110UpFrontGBP',
    coreLightUpFrontPlanId: 'coreLight110UpFrontGBP',
    sustainPlanId: 'sustainGBP',
    ongoingPlanId: 'ongoingMonthlyGBP',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyGBP',
    coreLight2TechMonthlyPlanId: 'coreLight2TechMonthly60GBP',
    coreLight2TechUpFrontPlanId: 'coreLight2TechUpFront160GBP',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthly40GBP',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFront110GBP',
  },
  GB: {
    properName: 'United Kingdom',
    currencySymbol: '£',
    currency: 'gbp',
    noTechPricePerMonth: 49,
    techPricePerMonth: 55,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 40,
    selfGuidedPricePerMonth: 30,
    coreLightPricePerMonth: 40,
    selfGuidedUpfrontSavings: 10,
    coreLightUpfrontSavings: 10,
    techUpgradePrice: 15,
    higherPriced443NoTech: 50,
    higherPriced443Tech: 65,
    techMonthlyPlanId: 'coreTechMonthly55GBP',
    noTechMonthlyPlanId: 'coreNoTechMonthly40GBP',
    selfGuidedMonthlyPlanId: 'selfGuided40GBP',
    selfGuidedTechMonthlyPlanId: 'selfGuidedTechMonthly40GBP',
    coreLightMonthlyPlanId: 'coreLight40GBP',
    techUpFrontPlanId: 'coreTechUpFront145GBP',
    blackFridayTechUpFrontPlanId: 'blackFridayCoreTechUpFrontGBP',
    noTechUpFrontPlanId: 'coreNoTechUpFront110GBP',
    blackFridayNoTechUpFrontPlanId: 'blackFridayNoTechUpFrontGBP',
    selfGuidedUpFrontPlanId: 'selfGuided110UpFrontGBP',
    coreLightUpFrontPlanId: 'coreLight110UpFrontGBP',
    sustainPlanId: 'sustainGBP',
    ongoingPlanId: 'ongoingMonthlyGBP',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyGBP',
    coreLight2TechMonthlyPlanId: 'coreLight2TechMonthly60GBP',
    coreLight2TechUpFrontPlanId: 'coreLight2TechUpFront160GBP',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthly40GBP',
    coreLight2NoTech6MonthsMonthlyPlanId: 'coreLight2NoTech6MonthsMonthly40GBP',
    coreLight2NoTech6MonthsUpFrontPlanId:
      'coreLight2NoTech6MonthsUpFront219GBP',
    coreLight2NoTech12MonthsMonthlyPlanId:
      'coreLight2NoTech12MonthsMonthly40GBP',
    coreLight2NoTech12MonthsUpFrontPlanId:
      'coreLight2NoTech12MonthsUpFront399GBP',
    coreLight2NoTech6MonthsDiscountedMonthlyPlanId:
      'coreLight2NoTech6MonthsMonthly36GBP',
    coreLight2NoTech12MonthsDiscountedMonthlyPlanId:
      'coreLight2NoTech12MonthsMonthly33GBP',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFront110GBP',
  },
  IE: {
    properName: 'Republic of Ireland',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    techPricePerMonth: 60,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 40,
    techMonthlyPlanId: 'coreLight2TechMonthly55EUR',
    noTechMonthlyPlanId: 'coreLight2NoTechMonthly40EUR',
    techUpFrontPlanId: 'coreLight2TechUpFront145EUR',
    blackFridayTechUpFrontPlanId: 'blackFridayCoreTechUpFrontEUR',
    noTechUpFrontPlanId: 'coreLight2NoTechUpFront40EUR',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontCentralEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthly40GBP',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFront110GBP',
    ongoingPlanId: 'ongoingMonthlyEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyEUR',
  },
  FR: {
    properName: 'France',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    techPricePerMonth: 50,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 40,
    techMonthlyPlanId: 'coreTechMonthlyCentralEuropeDiscountEUR',
    noTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    techUpFrontPlanId: 'coreTechUpFrontCentralEuropeDiscountEUR',
    blackFridayTechUpFrontPlanId:
      'blackFridayCoreTechUpFrontCentralEuropeDiscountEUR',
    noTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontCentralEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyEUR',
  },
  BE: {
    properName: 'Belgium',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    techPricePerMonth: 50,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 40,
    techMonthlyPlanId: 'coreTechMonthlyCentralEuropeDiscountEUR',
    noTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    techUpFrontPlanId: 'coreTechUpFrontCentralEuropeDiscountEUR',
    blackFridayTechUpFrontPlanId:
      'blackFridayCoreTechUpFrontCentralEuropeDiscountEUR',
    noTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontCentralEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyEUR',
  },
  DE: {
    properName: 'Germany',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    techPricePerMonth: 50,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 40,
    techMonthlyPlanId: 'coreTechMonthlyCentralEuropeDiscountEUR',
    noTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    techUpFrontPlanId: 'coreTechUpFrontCentralEuropeDiscountEUR',
    blackFridayTechUpFrontPlanId:
      'blackFridayCoreTechUpFrontCentralEuropeDiscountEUR',
    noTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontCentralEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyEUR',
  },
  DK: {
    properName: 'Denmark',
    currencySymbol: 'kr',
    currency: 'dkk',
    noTechPricePerMonth: 270,
    techPricePerMonth: 540,
    sustainPricePerMonth: 90,
    ongoingPricePerMonth: 270,
    techMonthlyPlanId: 'coreTechMonthlyDKK',
    noTechMonthlyPlanId: 'coreLight2NoTechMonthlyDKK',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthlyDKK',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFrontDKK',
    techUpFrontPlanId: 'coreTechUpFrontDKK',
    blackFridayTechUpFrontPlanId: 'blackFridayCoreTechUpFrontDKK',
    noTechUpFrontPlanId: 'coreLight2NoTechUpFrontDKK',
    blackFridayNoTechUpFrontPlanId: 'blackFridayNoTechUpFrontDKK',
    sustainPlanId: 'sustainDKK',
    ongoingPlanId: 'ongoingMonthlyDKK',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyDKK',
  },
  NL: {
    properName: 'Netherlands',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    techPricePerMonth: 50,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 40,
    techMonthlyPlanId: 'coreTechMonthlyCentralEuropeDiscountEUR',
    noTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    techUpFrontPlanId: 'coreTechUpFrontCentralEuropeDiscountEUR',
    blackFridayTechUpFrontPlanId:
      'blackFridayCoreTechUpFrontCentralEuropeDiscountEUR',
    noTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontCentralEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyEUR',
  },
  ES: {
    properName: 'Spain',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    techPricePerMonth: 50,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 40,
    techMonthlyPlanId: 'coreTechMonthlyCentralEuropeDiscountEUR',
    noTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    techUpFrontPlanId: 'coreTechUpFrontCentralEuropeDiscountEUR',
    blackFridayTechUpFrontPlanId:
      'blackFridayCoreTechUpFrontCentralEuropeDiscountEUR',
    noTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontCentralEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyEUR',
  },
  SE: {
    properName: 'Sweden',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 25,
    noTechMonthlyPlanId: 'coreLight2DigitalMonthlyNorthernEuropeEUR40',
    noTechUpFrontPlanId: 'coreLight2DigitalUpFrontNorthernEuropeEUR110',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontNorthernEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyNorthernEuropeEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyNorthernEuropeEUR',
  },
  FI: {
    properName: 'Finland',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 25,
    noTechMonthlyPlanId: 'coreLight2DigitalMonthlyNorthernEuropeEUR40',
    noTechUpFrontPlanId: 'coreLight2DigitalUpFrontNorthernEuropeEUR110',
    coreLight2NoTechMonthlyPlanId: 'coreLight2NoTechMonthlyCentralEuropeEUR',
    coreLight2NoTechUpFrontPlanId: 'coreLight2NoTechUpFrontCentralEuropeEUR',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontNorthernEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyNorthernEuropeEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyNorthernEuropeEUR',
  },
  NO: {
    properName: 'Norway',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 25,
    noTechMonthlyPlanId: 'coreLight2DigitalMonthlyNorthernEuropeEUR40',
    noTechUpFrontPlanId: 'coreLight2DigitalUpFrontNorthernEuropeEUR110',
    coreLight2NoTechMonthlyPlanId:
      'coreLight2DigitalMonthlyNorthernEuropeEUR40',
    coreLight2NoTechUpFrontPlanId:
      'coreLight2DigitalUpFrontNorthernEuropeEUR110',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontNorthernEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyNorthernEuropeEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyNorthernEuropeEUR',
  },
  IS: {
    properName: 'Iceland',
    currencySymbol: '€',
    currency: 'eur',
    noTechPricePerMonth: 49,
    sustainPricePerMonth: 10,
    ongoingPricePerMonth: 25,
    noTechMonthlyPlanId: 'coreLight2DigitalMonthlyNorthernEuropeEUR40',
    noTechUpFrontPlanId: 'coreLight2DigitalUpFrontNorthernEuropeEUR110',
    coreLight2NoTechMonthlyPlanId:
      'coreLight2DigitalMonthlyNorthernEuropeEUR40',
    coreLight2NoTechUpFrontPlanId:
      'coreLight2DigitalUpFrontNorthernEuropeEUR110',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontNorthernEuropeDiscountEUR',
    sustainPlanId: 'sustainEUR',
    ongoingPlanId: 'ongoingMonthlyNorthernEuropeEUR',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyNorthernEuropeEUR',
  },
  AU: {
    properName: 'Australia',
    currencySymbol: 'AU$',
    currency: 'aud',
    noTechPricePerMonth: 75,
    sustainPricePerMonth: 20,
    ongoingPricePerMonth: 75,
    noTechMonthlyPlanId: 'coreLight2DigitalMonthlyAustraliaAUD',
    noTechUpFrontPlanId: 'coreLight2DigitalUpFrontAustraliaAUD',
    coreLight2NoTechMonthlyPlanId: 'coreLight2DigitalMonthlyAustraliaAUD',
    coreLight2NoTechUpFrontPlanId: 'coreLight2DigitalUpFrontAustraliaAUD',
    blackFridayNoTechUpFrontPlanId:
      'blackFridayNoTechUpFrontAustraliaHigherPriceAUD',
    sustainPlanId: 'sustainAUD',
    ongoingPlanId: 'ongoingMonthlyAUD',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyAUD',
  },
  NZ: {
    properName: 'New Zealand',
    currencySymbol: 'NZ$',
    currency: 'nzd',
    noTechPricePerMonth: 80,
    sustainPricePerMonth: 20,
    ongoingPricePerMonth: 80,
    noTechMonthlyPlanId: 'coreLight2DigitalMonthlyNewZealandNZD',
    noTechUpFrontPlanId: 'coreLight2DigitalUpFrontNewZealandNZD',
    coreLight2NoTechMonthlyPlanId: 'coreLight2DigitalMonthlyNewZealandNZD',
    coreLight2NoTechUpFrontPlanId: 'coreLight2DigitalUpFrontNewZealandNZD',
    blackFridayNoTechUpFrontPlanId: 'blackFridayNoTechUpFrontNewZealandNZD',
    sustainPlanId: 'sustainNZD',
    ongoingPlanId: 'ongoingMonthlyNZD',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyNZD',
  },
  US: {
    properName: 'United States',
    currencySymbol: '$',
    currency: 'usd',
    noTechPricePerMonth: 75,
    sustainPricePerMonth: 20,
    ongoingPricePerMonth: 60,
    noTechMonthlyPlanId: 'coreLight2DigitalMonthlyUnitedStatesUSD',
    noTechUpFrontPlanId: 'coreLight2DigitalUpFrontUnitedStatesUSD',
    coreLight2NoTechMonthlyPlanId: 'coreLight2DigitalMonthlyUnitedStatesUSD',
    coreLight2NoTechUpFrontPlanId: 'coreLight2DigitalUpFrontUnitedStatesUSD',
    blackFridayNoTechUpFrontPlanId: 'blackFridayNoTechUpFrontUnitedStatesUSD',
    sustainPlanId: 'sustainUSD',
    ongoingPlanId: 'ongoingMonthlyUSD',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyUSD',
  },
  CA: {
    properName: 'Canada',
    currencySymbol: 'Can$',
    currency: 'cad',
    noTechPricePerMonth: 75,
    sustainPricePerMonth: 30,
    ongoingPricePerMonth: 75,
    noTechMonthlyPlanId: 'coreLight2DigitalMonthlyUnitedStatesCAD',
    noTechUpFrontPlanId: 'coreLight2DigitalUpFrontUnitedStatesCAD',
    coreLight2NoTechMonthlyPlanId: 'coreLight2DigitalMonthlyUnitedStatesCAD',
    coreLight2NoTechUpFrontPlanId: 'coreLight2DigitalUpFrontUnitedStatesCAD',
    blackFridayNoTechUpFrontPlanId: 'blackFridayNoTechUpFrontUnitedStatesCAD',
    sustainPlanId: 'sustainCAD',
    ongoingPlanId: 'ongoingMonthlyCAD',
    ongoingLegacyPlanId: 'ongoingLegacyMonthlyCAD',
  },
};

export const signupCutoffTimeMap: Record<
  | 'thursdayMidday'
  | 'fridayMidday'
  | 'wednesdayMidday'
  | 'wednesdayMidnight'
  | 'mondayMidnight',
  { day: number; hour: number }
> = {
  thursdayMidday: {
    day: 4,
    hour: 12,
  },
  fridayMidday: {
    day: 5,
    hour: 12,
  },
  wednesdayMidday: {
    day: 3,
    hour: 12,
  },
  wednesdayMidnight: {
    day: 3,
    hour: 0,
  },
  mondayMidnight: {
    day: 1,
    hour: 0,
  },
} as const;

export const countrySignupCutoffMap: Record<
  CountryCodeType,
  keyof typeof signupCutoffTimeMap
> = {
  GB: 'thursdayMidday',
  IE: 'wednesdayMidday',
  BE: 'wednesdayMidnight',
  FR: 'wednesdayMidnight',
  NL: 'wednesdayMidnight',
  DE: 'wednesdayMidnight',
  ES: 'mondayMidnight',
  DK: 'fridayMidday',
  AU: 'fridayMidday',
  SE: 'fridayMidday',
  FI: 'fridayMidday',
  NO: 'fridayMidday',
  IS: 'fridayMidday',
  NZ: 'fridayMidday',
  US: 'fridayMidday',
  CA: 'fridayMidday',
  Unknown: 'thursdayMidday',
} as const;

export const CURRENCY = [
  'gbp',
  'eur',
  'dkk',
  'usd',
  'aud',
  'nzd',
  'cad',
] as const;
export type CurrencyType = (typeof CURRENCY)[number];

/**
 * Braintree, where we take PayPal payments,
 * requires different merchant gateways
 * for different currencies
 */
export const braintreeMerchantGatewayMap: Record<CurrencyType, string> = {
  gbp: 'chrisourpathcouk',
  eur: 'ourpathEUR',
  dkk: 'ourpathDKK',
  usd: 'ourpathUSD',
  aud: 'ourpathAUD',
  nzd: 'ourpathNZD',
  cad: 'ourpathCAD',
};

// For countries where we're assuming the whole of the country is in the same timezone
// (in future we could split up Australia if we get lots of signups from Perth, which is 2 hours behind east
// Australia where 90% of the population is located)
//
// See:
// https://en.wikipedia.org/wiki/Tz_database
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
//
export const COUNTRY_CODE_TO_TIMEZONE_MAP: Partial<
  Record<CountryCodeWithoutUnknownType, string>
> = {
  AU: 'Australia/Sydney',
  NZ: 'Pacific/Auckland',
  GB: 'Europe/London',
};

export const STEPS_LEADERBOARD_COLOUR = [
  '#7FDCE6', // blue
  '#CCF1F5', // light blue
  '#CCD4D8', // stone grey
];

export const CONTENT_URLS_FOR_CONTENT_LIBRARY = [
  {
    contentURL: ourpathContent6v2CoreLightUrl,
    title: 'Consumer',
    key: 'content6v2cl',
  },
  {
    contentURL: ourpathContent6v2CoreLightAustralianUrl,
    title: 'Consumer Aus',
    key: 'content6v2auscl',
  },
  {
    contentURL: ourpathContent6v2CoreLightAmericanUrl,
    title: 'Consumer US',
    key: 'content6v2uscl',
  },
  {
    contentURL: selfPacedConsumerUrl,
    title: 'Consumer self-paced',
    key: 'content6v2clselfpaced',
  },
  {
    contentURL: rebootUrl,
    title: 'Consumer Reboot',
    key: 'content6v2reboot',
  },
  {
    contentURL: motivateUrl,
    title: 'Consumer Motivate',
    key: 'content6v2motivate',
  },
  {
    contentURL: exerciseUrl,
    title: 'Consumer Exercise',
    key: 'content6v2exercise',
  },
  {
    contentURL: americanRebootUrl,
    title: 'Consumer Reboot US',
    key: 'content6v2usreboot',
  },
  {
    contentURL: americanMotivateUrl,
    title: 'Consumer Motivate US',
    key: 'content6v2usmotivate',
  },
  {
    contentURL: americanExerciseUrl,
    title: 'Consumer Exercise US',
    key: 'content6v2usexercise',
  },
  {
    contentURL: ourpathContent6NDPPUrl,
    title: 'NDPP',
    key: 'content6v2ndpp',
  },
  {
    contentURL: ourpathContent6Type2DiabetesUrl,
    title: 'T2DM',
    key: 'content6v2t2dm',
  },
  {
    contentURL: wms1SelfGuidedContentUrl,
    title: 'WMS Level 1 (Self Guided)',
    key: 'content6v2wms1',
  },
  {
    contentURL: wms3CoachedContentUrl,
    title: 'WMS Level 3 (Coached)',
    key: 'content6v2wms3',
  },
  {
    contentURL: content7Url,
    title: 'Core 7',
    key: 'core7',
  },
  {
    contentURL: 'https://content7v2.secondnature.io',
    title: 'Core 7 v2',
    key: 'core7v2',
  },
  {
    contentURL: content7AmericanUrl,
    title: 'Core 7 US',
    key: 'core7us',
  },
  {
    contentURL: content7v2AmericanUrl,
    title: 'Core 7 v2 (US)',
    key: 'core7v2us',
  },
  {
    contentURL: 'https://growth.secondnature.io',
    title: 'Growth',
    key: 'growth',
  },
  {
    contentURL: 'https://maintain.secondnature.io',
    title: 'Maintain',
    key: 'maintain',
  },
  {
    contentURL: 'https://content7v2test541.secondnature.io',
    title: 'Core 7 v2 (Experiment X)',
    key: 'core7v2test541',
  },
  {
    contentURL: vitalityExperimentXUrl,
    title: 'Vitality (Experiment X)',
    key: 'vitalitytest541',
  },
  {
    contentURL: 'https://growthNoGroupNoCoachUS.secondnature.io',
    title: 'Growth (No Group No Coach US)',
    key: 'growthNoGroupNoCoachUS',
  },
  {
    contentURL: 'https://ndpp-test541.secondnature.io',
    title: 'NDPP Experiment X',
    key: 'ndppExperimentX',
  },
  {
    contentURL: wegovyAdaptContentUrl,
    title: 'Wegovy: Adapt',
    key: 'wegovyAdapt',
  },
  {
    contentURL: wegovyGrowContentUrl,
    title: 'Wegovy: Grow',
    key: 'wegovyGrow',
  },
  {
    contentURL: wegovyProgressContentUrl,
    title: 'Wegovy: Maintain',
    key: 'wegovyMaintain',
  },
  {
    contentURL: mounjaroAdaptContentUrl,
    title: 'Mounjaro: Adapt',
    key: 'mounjaroAdapt',
  },
];

export enum AppEnvStates {
  LOCAL = 'local',
  TEST = 'test',
  STAGING = 'staging',
  PREPROD = 'preprod',
  CYPRESS = 'cypress',
  PRODUCTION = 'production',
}
